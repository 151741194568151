<template>
  <div class="report-usages-list mt-10" :class="{light: !!(organizationId || customerId || chargingPointId)}">
    <div class="mb-10" v-if="organizationId || customerId || chargingPointId || meterDeviceId">
      <v-form @submit.prevent="search">
        <v-row dense justify="end">
          <v-col cols="auto"  style="min-width: 260px">
            <div>
              <v-text-field
                  :label="$t('search')"
                  clearable
                  outlined
                  persistent-placeholder
                  v-model="filters.search"
                  hide-details
              />
            </div>
          </v-col>
          <v-col cols="auto" v-if="!chargingPointId && !meterDeviceId">
            <v-select
                :label="$t('transaction-type')"
                clearable
                outlined
                persistent-placeholder
                hide-details
                :items="transactionTypes"
                v-model="filters.transactionType"
            />
          </v-col>
          <v-col cols="auto">
            <div style="min-width: 260px">
              <date-picker
                  :label="$t('period')"
                  range
                  outlined
                  persistent-placeholder
                  clearable
                  v-model="filters.dates"
                  hide-details
              />
            </div>
          </v-col>
          <v-col cols="auto" align-self="center">
            <v-btn type="submit" small>
              {{ $t("search") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <v-card class="mb-10" v-else>
      <v-card-title>
        {{ $t('search') }}
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="search">
          <v-row dense>
            <v-col md="3">
              <date-picker
                  :label="$t('period')"
                  range
                  clearable
                  v-model="filters.dates"
              />
            </v-col>
            <v-col md="3">
              <v-select
                  :label="$t('network')"
                  clearable
                  :items="[
                      {text: 'ZEborne', value: 'internal'},
                      {text: 'Gireve', value: 'gireve'},
                      {text: 'Hubject', value: 'hubject'},
                  ]"
                  v-model="filters.network"
              />
            </v-col>
            <v-col md="3">
              <textfield
                  :label="$t('ID')"
                  clearable
                  placeholder="cdr, session, transaction, external, authorization..."
                  v-model="filters.transactionField"
              />
            </v-col>
            <v-col md="3">
              <textfield
                  :label="$t('lastname')"
                  clearable
                  v-model="filters.customerName"
              />
            </v-col>
            <v-col md="3">
              <tree-select-organization
                  :label="$t('organization-id')"
                  clearable
                  v-model="filters.organizationId"
              />
            </v-col>
            <v-col md="3">
              <textfield
                  :label="$t('evse-id')"
                  clearable
                  v-model="filters.evseId"
              />
            </v-col>
            <v-col md="3">
              <textfield
                  :label="$t('customer-id')"
                  clearable
                  v-model="filters.customerId"
              />
            </v-col>
            <v-col md="3">
              <textfield
                  :label="$t('evco-id')"
                  clearable
                  v-model="filters.evcoId"
              />
            </v-col>
            <v-col md="3">
              <textfield
                  :label="$t('token-uid')"
                  clearable
                  v-model="filters.tokenUid"
              />
            </v-col>
            <v-col md="3">
              <textfield
                  :label="$tc('operator.operator')"
                  clearable
                  v-model="filters.operatorId"
              />
            </v-col>
            <v-col md="3">
              <textfield
                  :label="$t('charging-point-id')"
                  clearable
                  v-model="filters.chargingPointId"
              />
            </v-col>
            <v-col md="3">
              <textfield
                  :label="$t('charging-point-identifier')"
                  clearable
                  placeholder="EVB-12345"
                  v-model="filters.chargingPointIdentifier"
              />
            </v-col>
            <v-col md="3">
              <v-select
                  :label="$t('transaction-type')"
                  clearable
                  :items="[
                      {text: $t('transaction-charging-type.zms-by-zms'), value: 'zms-by-zms'},
                      {text: $t('transaction-charging-type.other-by-zms'), value: 'other-by-zms'},
                      {text: $t('transaction-charging-type.zms-by-other'), value: 'zms-by-other'},
                      {text: $t('transaction-charging-type.zms-via-scan-qr'), value: 'zms-via-scan-qr'},
                  ]"
                  v-model="filters.transactionType"
              />
            </v-col>
            <v-col md="3">
              <available-select
                  :placeholder="$t('country')"
                  type="country"
                  v-model="filters.country"
                  :filter="country => ['FR', 'BE', 'NL', 'DK', 'LU', 'GB', 'DE', 'ES', 'IT', 'CH'].includes(country.code)"
                  clearable
              />
            </v-col>

          </v-row>
          <v-row>
            <v-col class="text-right">
              <v-btn type="button" small class="mr-3" @click="clear">
                {{ $t('reset')}}
              </v-btn>
              <v-btn type="submit" color="primary" small>
                {{ $t("search") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <div></div>

    <v-row>
      <v-col class="table">
        <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="total"
        >
          <template #item.transactionType="{value}">
           <span>{{$t('transaction-charging-type.' + value)}}</span>
          </template>
          <template #item.token.network="{value}">
            {{ value === 'internal' ? 'ZEborne' : ucFirst(value) }}
          </template>
          <template #item.evse.network="{value}">
            {{ value === 'internal' ? 'ZEborne' : ucFirst(value) }}
          </template>
          <template #item.createdAt="{item}">
            {{ formatDate(item.createdAt, $t('format_datetime')) }}
          </template>
          <template #item.startTime="{item}">
            {{ formatDate(item.startTime, $t('format_datetime')) }}
          </template>
          <template #item.stopTime="{item}">
            {{ formatDate(item.stopTime, $t('format_datetime')) }}
          </template>

          <template #item.energy="{value}">
            {{ value/1000 }} {{ $t('usages.kwh') }}
          </template>

          <template #item.evse.connectorId="{item}">
            <template v-if="item.evse.deviceType === 'charging-point'">
              {{ item.evse.connectorId }} ({{ $t('enums.connector-type.' + item.evse.type) }})
            </template>
          </template>

          <template #item.vatRate="{value}">
            <span style="white-space: nowrap">{{ value }} %</span>
          </template>

          <template #item.vat="{value}">
            <span style="white-space: nowrap">{{ numberFormat(value) }} €</span>
          </template>

          <template #item.net="{value}">
            <span style="white-space: nowrap">{{ numberFormat(value) }} €</span>
          </template>


          <template #item.serviceCharge="{value}">
            <span style="white-space: nowrap">{{ numberFormat(value) }} €</span>
          </template>

          <template #item.price="{value}">
            <span style="white-space: nowrap" :style="{color: value > 0 ? 'red' : 'black'}">{{ numberFormat(value) }} €</span>
          </template>

          <template #item.evse.type="{item}">
            <template v-if="item.evse.deviceType === 'charging-point'">
              {{ $t('enums.connector-type.' + item.evse.type) }}
            </template>
          </template>

          <template #item.evse.locationType="{item}">
            {{ $t('enums.location-type.' + item.evse.locationType) }}
          </template>

          <template #item.customer.lastname="{item}">
            {{ item.customer.firstname }} {{ item.customer.lastname }}
          </template>

          <template #item.id="{value}">
            <span style="white-space: nowrap" v-if="value">
              {{ value }}
              <v-btn small icon @click="copyToClipboard(value)"><v-icon small>mdi-content-copy</v-icon></v-btn>
            </span>
          </template>

          <template #item.externalId="{item}">
            <span style="white-space: nowrap" v-if="item.externalId && item.externalId !== item.id">
              {{ item.externalId }}
              <v-btn small icon @click="copyToClipboard(item.externalId)"><v-icon small>mdi-content-copy</v-icon></v-btn>
            </span>
          </template>

          <template #item.externalAuthorizationId="{item}">
            <span style="white-space: nowrap" v-if="item.externalAuthorizationId && item.externalAuthorizationId !== item.authorizationId">
              {{ item.externalAuthorizationId }}
              <v-btn small icon @click="copyToClipboard(item.externalAuthorizationId)"><v-icon small>mdi-content-copy</v-icon></v-btn>
            </span>
          </template>

          <template #item.sessionId="{value}">
            <span style="white-space: nowrap" v-if="value">
              {{ value }}
              <v-btn small icon @click="copyToClipboard(value)"><v-icon small>mdi-content-copy</v-icon></v-btn>
            </span>
          </template>

          <template #item.organization.id="{value}">
            <span style="white-space: nowrap" v-if="value">
              {{ value }}
              <v-btn small icon @click="copyToClipboard(value)"><v-icon small>mdi-content-copy</v-icon></v-btn>
            </span>
          </template>

          <template #item.empOrganization.id="{value}">
            <span style="white-space: nowrap" v-if="value">
              {{ value }}
              <v-btn small icon @click="copyToClipboard(value)"><v-icon small>mdi-content-copy</v-icon></v-btn>
            </span>
          </template>

          <template #item.authorizationId="{value}">
            <span style="white-space: nowrap" v-if="value">
              {{ value }}
              <v-btn small icon @click="copyToClipboard(value)"><v-icon small>mdi-content-copy</v-icon></v-btn>
            </span>
          </template>

          <template #item.evse.chargingPointId="{value}">
            <span style="white-space: nowrap" v-if="value">
              {{ value }}
              <v-btn small icon @click="copyToClipboard(value)"><v-icon small>mdi-content-copy</v-icon></v-btn>
            </span>
          </template>

          <template #item.customer.id="{value}">
            <span style="white-space: nowrap" v-if="value">
              {{ value }}
              <v-btn small icon @click="copyToClipboard(value)"><v-icon small>mdi-content-copy</v-icon></v-btn>
            </span>
          </template>

          <template #item.duration="{value}">
            <span style="white-space: nowrap" v-if="value">
              {{ formatDuration(value) }}
            </span>
          </template>

          <template #item.categoryChargingPoint="{value}">
            {{ $t(`reports.${value}`) }}
          </template>


        </v-data-table>
      </v-col>
    </v-row>


    <v-row class="mb-5">
      <v-col>
        <v-btn @click="downloadCsv" :loading="loadingExport" :disabled="loadingExport" type="button">
          <v-icon left>mdi-file-delimited-outline</v-icon>
          {{ $t("export") }}
        </v-btn>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import ReportRepository from "@repository/ReportRepository";
import dayjs from "dayjs";
import TreeSelectOrganization from "@blocks/Select/TreeSelectOrganization.vue";
import AvailableSelect from "@blocks/Select/AvailableSelect.vue";

export default {
  components: {TreeSelectOrganization, AvailableSelect},
  data() {
    return {
      loadingExport: false,
      total: 0,
      items: [],
      filters: {},
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
      },
    }
  },

  props: {
    organizationId: String,
    chargingPointId: String,
    meterDeviceId: String,
    customerId: String,
    tokenUid: String,
  },

  mounted() {

  },

  watch: {
    options() {
      this.loadData()
    }
  },


  computed: {

    transactionTypes() {
      if(this.organizationId) {
       return [
          {text: this.$t('reports.public-charging-point'), value: 'public-charging-point'},
          {text: this.$t('reports.home-charging-point'), value: 'home-charging-point'},
          {text: this.$t('reports.organization-charging-point'), value: 'organization-charging-point'},
        ]
      } else if(this.customerId) {
        return [
          {text: this.$t('reports.public-charging-point'), value: 'public-charging-point'},
          {text: this.$t('reports.home-charging-point'), value: 'home-charging-point'},
          {text: this.$t('reports.organization-charging-point'), value: 'organization-charging-point'},
        ]
      } else {
        return [
          {text: this.$t('transaction-charging-type.zms-by-zms'), value: 'zms-by-zms'},
          {text: this.$t('transaction-charging-type.other-by-zms'), value: 'other-by-zms'},
          {text: this.$t('transaction-charging-type.zms-by-other'), value: 'zms-by-other'},
          {text: this.$t('transaction-charging-type.zms-via-scan-qr'), value: 'zms-via-scan-qr'},
        ]
      }
    },

    headers() {
      if(this.chargingPointId) {
        return [
          {"text": this.$t('transaction-id'), value: 'transactionId'},
          {"text": this.$t('start-time'), value: 'startTime'},
          {"text": this.$t('stop-time'), value: 'stopTime'},
          {"text": this.$t('duration'), value: 'duration'},
          {"text": this.$t('energy'), value: 'energy'},
          {"text": this.$t('charging-point.connector'), value: 'evse.connectorId'},
          // {"text": this.$t('price'), value: 'price'},
        ]
      }
      if(this.meterDeviceId) {
        return [
          {"text": this.$t('start-time'), value: 'startTime'},
          {"text": this.$t('stop-time'), value: 'stopTime'},
          {"text": this.$t('duration'), value: 'duration'},
          {"text": this.$t('energy'), value: 'energy'},
          // {"text": this.$t('price'), value: 'price'},
        ]
      }
      if(this.organizationId || this.customerId) {
        return [
          {"text": this.$t('transaction-id'), value: 'transactionId'},
          {"text": this.$t('start-time'), value: 'startTime'},
          {"text": this.$t('stop-time'), value: 'stopTime'},
          {"text": this.$tc('category'), value: 'categoryChargingPoint', width: 200, sortable: false},
          {"text": this.$t('evse-name'), value: 'evse.name', width: 200},
          {"text": this.$tc('rfid.rfid'), value: 'token.uid'},
          {"text": this.$tc('customer.customer', 1), value: 'customer.lastname'},
          {"text": this.$t('energy'), value: 'energy'},
          {"text": this.$t('price'), value: 'finalCost'},
        ]
      }
      return [
        {"text": this.$t('created-at'), value: 'createdAt'},
        {"text": this.$t('cdr-id'), value: 'id'},
        {"text": this.$t('transaction-type'), value: 'transactionType', width: 200, sortable: false,},
        {"text": this.$t('session-id'), value: 'sessionId', width: 200},
        {"text": this.$t('external-id'), value: 'externalId', width: 200},
        {"text": this.$t('external-authorization-id'), value: 'externalAuthorizationId'},
        {"text": this.$t('cpo-network'), value: 'evse.network'},
        {"text": this.$t('emp-network'), value: 'token.network'},
        {"text": this.$t('type'), value: 'type'},
        {"text": this.$t('cpo-account-number'), value: 'organization.accountNumber'},
        {"text": this.$t('cpo-organization'), value: 'organization.name'},
        {"text": this.$t('cpo-organization-id'), value: 'organization.id'},
        {"text": this.$t('emp-account-number'), value: 'empOrganization.accountNumber'},
        {"text": this.$t('emp-organization'), value: 'empOrganization.name'},
        {"text": this.$t('emp-organization-id'), value: 'empOrganization.id'},
        {"text": this.$t('authorization-id'), value: 'authorizationId'},
        {"text": this.$t('charging-point-id'), value: 'evse.chargingPointId'},
        {"text": this.$t('evse-id'), value: 'evse.evseId'},
        {"text": this.$t('country'), value: 'evse.address.country'},
        {"text": this.$t('evse-name'), value: 'evse.name', width: 200},
        {"text": this.$t('cpo-name'), value: 'operator.name'},
        {"text": this.$t('customer-id'), value: 'customer.id'},
        {"text": this.$tc('customer.customer', 1), value: 'customer.lastname'},
        {"text": this.$t('evco-id'), value: 'customer.evcoId'},
        {"text": this.$t('start-time'), value: 'startTime'},
        {"text": this.$t('stop-time'), value: 'stopTime'},
        {"text": this.$t('duration'), value: 'duration'},
        {"text": this.$t('energy'), value: 'energy'},
        {"text": this.$t('meter-start'), value: 'startMeter'},
        {"text": this.$t('meter-stop'), value: 'stopMeter'},

        {"text": this.$t('energyKwhPrice'), value: 'energyKwhPrice'},
        {"text": this.$t('energyCost'), value: 'energyCost'},
        {"text": this.$t('cpoMargin'), value: 'cpoMargin'},
        {"text": this.$t('cpoPrice'), value: 'cpoPrice'},

        // {"text": this.$t('siteOwnerMargin'), value: 'siteOwnerMargin'},
        // {"text": this.$t('serviceProviderMargin'), value: 'serviceProviderMargin'},
        {"text": this.$t('roamingCharges'), value: 'roamingCharges'},
        {"text": this.$t('emspMargin'), value: 'emspMargin'},

        {"text": this.$t('service-charge'), value: 'serviceCharges'},

        {"text": this.$t('net'), value: 'netCost'},
        {"text": this.$tc('vat-rate.vat-rate'), value: 'vatRate'},
        {"text": this.$t('vat'), value: 'valueAddedTax'},
        {"text": this.$t('price'), value: 'finalCost'},

        {"text": this.$t('paymentProcessingFee'), value: 'paymentProcessingFee'},

        {"text": this.$t('currency'), value: 'currency'},
        {"text": this.$t('private'), value: 'evse.private'},
        {"text": this.$t('free-usage'), value: 'evse.freeUsage'},
        {"text": this.$t('plug-type'), value: 'evse.type'},
        {"text": this.$t('plug-power-type'), value: 'evse.powerType'},
        {"text": this.$t('token-type'), value: 'token.type'},
        {"text": this.$t('cpo-operator-id'), value: 'operator.id'},
        {"text": this.$t('emp-operator-id'), value: 'empOperator.id'},
        {"text": this.$t('transaction-id'), value: 'transactionId'},
        // {"text": this.$t('cpo-share'), value: 'cpoShare'},
        {"text": this.$t('authorization-method'), value: 'authorizationMethod'},
        {"text": this.$t('authorization-type'), value: 'authorizationType'},
        {"text": this.$t('location-type'), value: 'evse.locationType'},
        {"text": this.$tc('rfid.rfid'), value: 'token.uid'},
        {"text": this.$t('rfid-name'), value: 'token.name'},
      ]
    },
  },


  methods: {

    getQuery() {
      let data = this.cloneObj(this.filters)

      if (data.dates) {
        data.dateFrom = dayjs(data.dates[0], 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss')
        data.dateTo = dayjs(data.dates[1], 'YYYY-MM-DD').format('YYYY-MM-DD 23:59:59')
        delete data.dates
      }
      if(data.organizationId) {
        data.organizationIds = [data.organizationId]
      } else if(this.organizationId) {
        data.organizationIds = [this.organizationId]
      }
      delete data.organizationId
      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage

      if(this.customerId) {
        data.customerId = this.customerId
      }
      if(this.chargingPointId) {
        data.chargingPointId = this.chargingPointId
      } else if(this.meterDeviceId) {
        data.meterDeviceId = this.meterDeviceId
      }
      if(this.tokenUid) {
        data.tokenUid = this.tokenUid
      }
      if (this.options.sortBy.length > 0) {
        data.orderBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.orderDirection = this.options.sortDesc[0] ? 'desc' : 'asc'
      }
      return data;
    },


    search() {
      if(this.options.page > 1) {
        this.options.page = 1
      } else {
        this.loadData();
      }
    },


    loadData() {
      this.showLoading(true)
      ReportRepository.usages(this.getQuery()).then(response => {
        this.total = response.total
        this.items = response.items
        this.showLoading(false)
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message ?? e.message)
        this.showLoading(false)
      })
    },


    clear() {
      this.filters = {}
      this.loadData();
    },


    downloadCsv() {
      this.loadingExport = true
      ReportRepository.usagesExport(this.getQuery()).then(() => {
        this.loadingExport = false
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message ?? e.message)
        this.loadingExport = false
      })
    },


  }
}
</script>

<style lang="scss">
  .report-usages-list {
    &.light {
      .v-data-table {
        padding: 4px;
        background-color: white !important;
      }
    }
  }
</style>
